const initialState = {
  currentProduct: "ru",
  currentCurrency: "RUB",
  min: 30000,
  max: 20000000,
  step: 10000,
  currentVal: 30000,
  currentActiveBond: "RU000A10AYU6",
  lastActiveBonds: {
    ru: "RU000A10AYU6",
  },
  products: {
    ru: {
      RU000A10AYU6: {
        rate: 26.5,
        cost: 1000,
        date: "2028-10-02",
        term: "25.02.2025 - 10.02.2028",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "МФК Мани Мен",
          kz: "МФК Мани Мен",
          en: "MFK MoneyMen",
        },
      },
      RU000A109T74: {
        rate: 26,
        cost: 1000,
        date: "2027-10-02",
        term: "17.10.2024 - 02.10.2027",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект 001P-02",
          kz: "АйДи Коллект 001P-02",
          en: "ID Collect 001P-02",
        },
      },
      RU000A108L65: {
        rate: 19,
        cost: 1000,
        date: "2027-05-20",
        term: "04.06.2024 - 20.05.2027",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-07",
          kz: "АйДи Коллект-07",
          en: "ID Collect-07",
        },
      },
      RU000A107C34: {
        rate: 18,
        cost: 1000,
        date: "2026-11-21",
        term: "07.02.2023 - 21.11.2026",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-06",
          kz: "АйДи Коллект-06",
          en: "ID Collect-06",
        },
      },
      RU000A1071Z8: {
        rate: 16,
        cost: 1000,
        date: "2026-10-03",
        term: "19.10.2023- 03.10.2026",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-05",
          kz: "АйДи Коллект-05",
          en: "ID Collect-05",
        },
      },
      RU000A106XT3: {
        rate: 16.5,
        cost: 1000,
        date: "2026-09-09",
        term: "26.09.2023- 09.09.2026",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-04",
          kz: "АйДи Коллект-04",
          en: "ID Collect-04",
        },
      },
      RU000A1065M8: {
        rate: 15,
        cost: 1000,
        date: "2026-04-10",
        term: "16.05.2023 - 10.04.2026",
        termM: 36,
        sign: "₽",
        currency: "RUB",
        name: {
          ru: "АйДи Коллект-03",
          kz: "АйДи Коллект-03",
          en: "ID Collect-03",
        },
      },
      name: "₽",
    },
  },
};

export const calcBondsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_BONDS_CALC": {
      return {
        ...state,
        currentTerm: 0,
        currentPercent: 0,
      };
    }
    case "UPDATE_BONDS_CALC_PRODUCT": {
      const currentProduct = action.data;
      const currentActiveBond = state.lastActiveBonds[currentProduct];
      const currentCurrency =
        state.products[currentProduct][currentActiveBond].currency;

      return {
        ...state,
        currentProduct: currentProduct,
        currentCurrency: currentCurrency,
        currentActiveBond: currentActiveBond,
      };
    }
    case "UPDATE_BONDS_CALC_TERM": {
      return {
        ...state,
        currentTerm: action.data,
        currentPercent: action.data,
      };
    }
    case "UPDATE_BONDS_CALC_CURRENCY_VAL": {
      return {
        ...state,
        currentVal: action.data,
      };
    }
    case "UPDATE_BONDS_CALC_ACTIVE_BONDS": {
      const currentProduct = state.currentProduct;

      return {
        ...state,
        lastActiveBonds: {
          ...state.lastActiveBonds,
          [currentProduct]: action.data,
        },
      };
    }
    case "UPDATE_BONDS_CALC_CURRENT_ACTIVE_BOND": {
      const currentProduct = state.currentProduct;
      const currentActiveBond = action.data;
      const currentCurrency =
        state.products[currentProduct][currentActiveBond].currency;

      return {
        ...state,
        currentCurrency: currentCurrency,
        currentActiveBond: currentActiveBond,
      };
    }
    default: {
      return state;
    }
  }
};
